.sleep {
  background: linear-gradient(45deg, #ff0000, #00ff00);
}

.compete {
  background: linear-gradient(223.26deg, #ffffff 19.83%, #000000 100%);
}

.league {
  background: linear-gradient(223.26deg, #ecf100 19.83%, #ff0000 76.62%, #ff0000 76.63%);
}

.srb {
  background: linear-gradient(64.3deg, #b5c500 26.27%, #00fff0 67.75%);
}

.aramb {
  background: linear-gradient(223.26deg, #b500c5 19.83%, #00c2ff 77.03%);
}

.rome {
  background: linear-gradient(35.7deg, #000000 26.15%, #00fff0 60.42%);
}

.tenets {
  background: linear-gradient(106.13deg, #00a3ff 5.27%, #c50000 80.02%);
}

.thicc {
  background: linear-gradient(106.13deg, #ff00e5 5.27%, #00ffd1 80.02%);
}

.looks {
  background: linear-gradient(24.13deg, #ffffff 5.27%, #00ffd1 80.02%);
}

.starterpack {
  width: 100%;
  height: 100%;
  background: linear-gradient(106.13deg, #001aff 5.27%, #d30000 80.02%);
}

.phraseContainer {
  position: absolute;
  top: 20vh;
  right: 0;
  width: 30vw;
}

.inLeagueText {
  color: #00ff19;
  font-size: 120px;
  position: absolute;
  right: 30px;
  bottom: 100px;
  width: 600px;
}

.leagueMaps {
  color: black;
  font-size: 60px;
  position: absolute;
  width: 550px;
  top: 150px;
  right: 50px;
}

.summonersRift {
  color: red;
  font-size: 75px;
}

.aram {
  color: #00ff00;
  font-size: 130px;
}

.spqr {
  position: absolute;
  bottom: 50px;
  right: 50px;
}

.video {
  margin: 0 auto;
  max-width: 100%;
  width: 480px;
  height: 650px;
  max-height: calc(100vw / 0.738);
}

.biceps {
  position: absolute;
  font-size: 100px;
  right: 100px;
  bottom: 100px;
}

.starterPackContent {
  margin-left: 30%;
  width: 70%;
  position: relative;
  height: 100%;
}

.coke {
  position: absolute;
  width: 250px;
  left: 200px;
  top: 10px;
}

.steak {
  position: absolute;
  top: 10px;
  right: 10px;
}

.shorts {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.lego {
  position: absolute;
  bottom: 0px;
  right: 700px;
}

.flipflop {
  position: absolute;
  width: 300px;
  top: 300px;
  right: 300px;
}

.sp2 {
  position: absolute;
  right: 400px;
  bottom: 0px;
  height: 400px;
}
