.app {
  height: 100vh;
  width: 100vw;
}

.tindr {
  left: 50px;
  top: 50px;
  position: absolute;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.kovath {
  color: white;
  font-size: 8vw;
  margin: 0 0 0 50px;
  z-index: 1;
}

.shootyourshotcontainer {
  margin-left: 50px;
  position: relative;
  width: 295px;
  color: black;
}

.shootyourshotcontainer:hover {
  cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="40" height="50" style="font-size: 27px;"><text y="40">💦</text></svg>'),
    auto;
}

.textShoot {
  position: absolute;
  font-size: 24px;
  top: 20px;
  left: 20px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.textLeague {
  font-size: 16px;
  position: absolute;
  top: 50px;
  left: 20px;
  opacity: 0.5;
}

.slideIntoDMS {
  z-index: 1;
  color: white;
  position: relative;
  margin-left: -220px;
}

.shootyourshotLink:hover + .slideIntoDMS {
  transition: 0.3s;
  transition-timing-function: ease-out;
  margin-left: 75px;
}
