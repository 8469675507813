.container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
}

.image {
  margin: 0 auto;
  max-width: 100%;
  width: 480px;
  height: 650px;
  max-height: calc(100vw / 0.738);
}

.main {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
